@import "../variables.module";

.inputWrapper {
  display: flex;
  flex-direction: column;
  padding: $Dmw/2;
}

.standard-button {
  padding: 15px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: none;
  overflow: hidden;
  background-color: #262c52;

  .gradient-item {
    transition: opacity 500ms ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-origin: border-box !important;
  }

  //Gives the illusion of a changed gradient
  &:hover {
    .gradient-item {
      opacity: 0.5;
    }
  }

  //----------------Hover

  transition:
    box-shadow 500ms $easeInOut3,
    background-color 200ms $easeInOut3,
    opacity 200ms $easeInOut3,
    border 200ms $easeInOut3;

  &.mobile-btn {
    display: none;
  }

  &.dice-game {
    background-color: #444e7e !important;
    border-color: transparent !important;
  }

  &:hover {
    cursor: pointer;

    // box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    &.de-active {
      opacity: 1;
    }
  }

  &.no-radius-border {
    border-radius: 0px 6px 6px 0px;
  }

  &.radius-border {
    border-radius: 6px;
  }

  &.de-active {
    pointer-events: none !important;
    opacity: 0.333 !important;

    .inner-content {
      .icon-container {
        svg {
          fill: #5e679e33;

          path {
            fill: #5e679e33 !important;
          }
        }
      }
    }
  }

  &.side-padding {
    padding-left: 30px;
    padding-right: 30px;
  }

  .icon-container {
    svg path {
      transition: fill $tranTime $easeInOut3;
    }
  }

  .value-icon {
    position: absolute;
    left: 12px;
    display: flex;
  }

  .inner-content {
    display: flex;
    align-items: center;
    z-index: 2;
    position: relative;
    font-size: 13px;

    span {
      transition: color 200ms $easeInOut3;
      // font-family: $primaryFont;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      line-height: normal;
      // font-weight: 600;

      @media (max-width: $breakPointThree) {
        font-size: 12px;
      }
    }

    // display: inliune-block;
    .icon-container,
    .icon-container-two {
      width: 15px;
      // height: 15px;
      // margin-left: 10px;
      transition: transform 200ms $easeInOut3;
      display: flex;

      svg {
        width: 100%;
      }
    }
  }

  &.text-only {
    background-color: transparent;
    padding: 0;
    border: none;
    border-radius: 0;
    height: auto;
    min-width: fit-content;

    &:hover {
      span {
        color: $fontColor;
      }
    }
  }

  //Logo ------
  &.logo {
    // padding: 20px;
    background-color: transparent;
    border: none;

    &.footer {
      // border: 1px solid #fff;
      width: 200px;

      @media screen and (max-width: 769px) {
        width: 120px;
        padding-left: 0px;
      }
    }

    span {
      display: none;
    }
  }

  &.logo-header {
    // padding: 20px;
    padding-left: 0px;
    background-color: transparent;
    border: none;

    span {
      display: none;
    }
  }

  &.search {
    border-radius: 6px;
    border: 1px solid #363c5e !important;
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.15) 0%,
      rgba(94, 103, 158, 0.12) 100%
    );

    &:hover {
      border-radius: 6px;
      border: 1px solid #9a9fb8;
      background:
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.05) 0%,
          rgba(0, 119, 219, 0.05) 100%
        ),
        linear-gradient(
          180deg,
          rgba(124, 131, 177, 0.15) 0%,
          rgba(124, 131, 177, 0.15) 100%
        );
      box-shadow: 0px 0px 4px 0px #5e679e inset;
    }
  }

  //Messages --------------------------------
  &.messenger,
  &.gift,
  &.emote-select {
    display: flex;
    width: 42px;
    height: 42px;
    padding: 0;
    border: 1px solid;
    border-radius: 6px;

    .inner-content {
      .icon-container {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        margin: 0;

        svg,
        path {
          fill: #9aa0c1;
        }
      }
    }

    &:hover {
      .inner-content {
        .icon-container {
          svg,
          path {
            fill: #c0c4db;
          }
        }
      }
    }
  }

  &.emote-select {
    background-color: transparent;
    border: none;
  }

  &.emote-list-select {
    width: auto;
    position: relative;
    height: auto;
    padding: 8px;
    margin: 2px;
    border: none;
    border-radius: 6px;
    border: 1px solid #3d456f;
    background: linear-gradient(
      180deg,
      rgba(94, 103, 158, 0.2) 0%,
      rgba(84, 92, 141, 0.2) 100%
    );
  }

  &.square-icon {
    display: flex;
    min-width: 0;
    background-color: $primaryBackgroundColor;

    .inner-content {
      .icon-container {
        width: 20px;
        height: 20px;
        margin: 0;
        display: flex;
        align-items: center;
      }
    }
  }

  &.modal-close-btn {
    position: absolute;
    top: 2rem;
    right: 30px;
    width: 30px;
    height: 30px;
    // padding: 5px;
    border-radius: 50%;
    padding: 0;
    border: none;
    background-color: $fourthBackground;

    @media (max-width: $modalBreakPointOne) {
      top: 50px;
    }

    .inner-content {
      .icon-container {
        justify-content: center;
      }

      svg,
      path {
        fill: #fff;
        width: 10px;
      }
    }
  }

  &.modal-close-btn2 {
    position: absolute;
    top: 31px;
    right: 30px;
    width: 30px;
    height: 30px;
    // padding: 5px;
    border-radius: 50%;
    padding: 0;
    border: none;
    background-color: $fourthBackground;

    @media (max-width: 910px) {
      top: 33px;
    }

    @media (max-width: 500px) {
      top: 34px;
    }

    .inner-content {
      .icon-container {
        justify-content: center;
      }

      svg,
      path {
        fill: #fff;
        width: 10px;
      }
    }
  }

  &.close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0;
    border: none;
    background-color: $fourthBackground;

    .inner-content {
      .icon-container {
        justify-content: center;
      }

      svg,
      path {
        fill: #fff;
        width: 10px;
      }
    }
  }

  &.modal-close-btn2 {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    // padding: 5px;
    border-radius: 50%;
    padding: 0;
    border: none;
    background-color: $fourthBackground;

    @media (max-width: 910px) {
      top: 33px;
    }

    @media (max-width: 500px) {
      top: 34px;
    }

    .inner-content {
      .icon-container {
        justify-content: center;
      }

      svg,
      path {
        fill: #fff;
        width: 10px;
      }
    }
  }

  &.originals {
    .inner-content {
      gap: 12px !important;
      text-align: center;

      .icon-container {
        padding-top: 3px;
      }
    }
  }

  &.side-menu-nav {
    width: 100%;
    min-width: 0;
    // border: 1px solid #fff;
    height: 38px;
    padding: 0;

    font-size: $fontSizeThree;
    justify-content: flex-start;
    background-color: transparent;
    border: none;
    transition:
      box-shadow 200ms,
      background-color 200ms,
      opacity 200ms;

    .icon-container {
      width: 18px !important;
      // transform: translateY(-2px);
    }

    span {
      transform: translateY(-1px);
    }

    &.new-game {
      .inner-content {
        span {
          color: #fff;
        }

        svg,
        path {
          fill: #7dbaef !important; // It's important to apply color on Case Battles Icon on Navbar
        }

        &:hover {
          svg,
          path {
            fill: white;
          }
        }
      }

      span:nth-child(2) {
        height: 24px;
        display: flex;
        align-items: center;
        transform: translateX(0px);
        transition: transform 0.1s ease-in-out;
        font-size: 10px;
        padding: 7px 8px;
        margin-left: auto;
        transform: translateX(-2px);
        text-transform: uppercase;

        border-radius: 4.222px;
        border: 1px solid #87c1f2;
        background: radial-gradient(
          76.33% 100% at 50% 100%,
          rgba(0, 119, 219, 0.5) 0%,
          rgba(0, 119, 219, 0.1) 100%
        );
        box-shadow: 0 0 6.111px rgba(0, 119, 219, 0.3);
      }

      &:hover {
        span:nth-child(2) {
          background: radial-gradient(
            76.33% 100% at 50% 100%,
            rgba(59, 36, 36, 0.5) 0%,
            rgba(59, 36, 36, 0.1) 70%,
            rgba(59, 36, 36, 0) 100%
          );
          transform: translateX(-6px);
          transition: transform 0.1s ease-in-out;
        }
      }

      &.active-item {
        span:nth-child(2) {
          background: radial-gradient(
            76.33% 100% at 50% 100%,
            rgba(59, 36, 36, 0.5) 0%,
            rgba(59, 36, 36, 0.1) 70%,
            rgba(59, 36, 36, 0) 100%
          );
          box-shadow: 0px 0px 15px 10px rgba(0, 119, 219, 0.3);
          transform: translateX(-6px);
          transition: transform 0.1s ease-in-out;
        }

        .inner-content {
          svg,
          path {
            fill: white !important;
          }
        }
      }
    }

    &.flyout-activation-item {
      transition:
        background-color 150ms,
        opacity 200ms;
    }

    .inner-content {
      align-items: center;
      flex-direction: row-reverse;
      gap: 10px;
      padding-left: 15px;

      span {
        transition: color 0.2s !important;
        color: #9aa0c1;
      }

      .icon-container {
        width: 14px;
        margin: 0;

        svg {
          path {
            transition: fill $tranTime;
            fill: $fontColorTwo;
          }
        }
      }

      .icon-container-nav {
        width: 18px;
        margin: 0;

        svg {
          path {
            transition: fill $tranTime;
            fill: $fontColorTwo;
          }
        }
      }
    }

    .icon-container svg path,
    circle {
      transition: fill $tranTime $easeInOut3;
      fill: $fontColorTwo;
    }

    &:hover {
      background-color: #0077c7;

      .inner-content {
        span {
          color: white;
        }

        .icon-container svg path,
        circle {
          fill: white !important;
        }
      }
    }

    &.active-item {
      border-radius: 6px;
      // background: #171d3b;
      background-color: #0077c7;

      .inner-content {
        span {
          color: #fff;
        }

        .icon-container svg path,
        circle {
          fill: white;
        }
      }
    }
  }

  &.side-menu-nav-mini {
    width: 100%;
    min-width: 0;
    height: 38px;
    padding: 0;
    background-color: transparent;

    font-size: $fontSizeThree;
    justify-content: flex-start;
    border: none;
    transition:
      box-shadow 200ms,
      background-color 200ms,
      opacity 200ms;

    .icon-container {
      width: 18px !important;
      transform: translateY(-1px);
    }

    span {
      transform: translateY(-1px);
    }

    &.new-game {
      .inner-content {
        span {
          color: #fff;
        }

        svg,
        path {
          fill: #7dbaef !important; // It's important to apply color on Case Battles Icon on Navbar
        }

        &:hover {
          svg,
          path {
            fill: white;
          }
        }
      }

      span:nth-child(2) {
        height: 24px;
        display: flex;
        align-items: center;
        transform: translateX(0px);
        transition: transform 0.1s ease-in-out;
        font-size: 10px;
        padding: 7px 8px;
        margin-left: auto;
        transform: translateX(-2px);
        text-transform: uppercase;

        border-radius: 4.222px;
        border: 1px solid #87c1f2;
        background: radial-gradient(
          76.33% 100% at 50% 100%,
          rgba(0, 119, 219, 0.5) 0%,
          rgba(0, 119, 219, 0.1) 100%
        );
        box-shadow: 0 0 6.111px rgba(0, 119, 219, 0.3);
      }

      &:hover {
        span:nth-child(2) {
          background: radial-gradient(
            76.33% 100% at 50% 100%,
            rgba(59, 36, 36, 0.5) 0%,
            rgba(59, 36, 36, 0.1) 70%,
            rgba(59, 36, 36, 0) 100%
          );
          box-shadow: 0px 0px 15px 10px rgba(0, 119, 219, 0.3);
          transform: translateX(-6px);
          transition: transform 0.1s ease-in-out;
        }
      }

      &.active-item {
        span:nth-child(2) {
          background: radial-gradient(
            76.33% 100% at 50% 100%,
            rgba(59, 36, 36, 0.5) 0%,
            rgba(59, 36, 36, 0.1) 70%,
            rgba(59, 36, 36, 0) 100%
          );
          box-shadow: 0px 0px 15px 10px rgba(0, 119, 219, 0.3);
          transform: translateX(-6px);
          transition: transform 0.1s ease-in-out;
        }

        .inner-content {
          svg,
          path {
            fill: white !important;
          }
        }
      }
    }

    &.flyout-activation-item {
      transition:
        background-color 150ms,
        opacity 200ms;
    }

    .inner-content {
      align-items: center;
      flex-direction: row-reverse;
      gap: 10px;
      padding-left: 15px;

      span {
        transition: color 0.2s !important;
        color: #9aa0c1;
      }

      .icon-container {
        width: 14px;
        margin: 0;

        svg {
          path {
            transition: fill $tranTime;
            fill: $fontColorTwo;
          }
        }
      }

      .icon-container-nav {
        width: 18px;
        margin: 0;

        svg {
          path {
            transition: fill $tranTime;
            fill: $fontColorTwo;
          }
        }
      }
    }

    .icon-container svg path,
    circle {
      transition: fill $tranTime $easeInOut3;
      fill: $fontColorTwo;
    }

    &:hover {
      background-color: #171d3b;

      .inner-content {
        span {
          color: white;
        }

        .icon-container svg path,
        circle {
          fill: white !important;
        }
      }
    }

    &.active-item {
      border-radius: 6px;
      background: #171d3b;

      .inner-content {
        span {
          color: #fff;
        }

        .icon-container svg path,
        circle {
          fill: white;
        }
      }
    }
  }

  &.side-menu-nav-mini-originals {
    width: 100%;
    min-width: 0;
    height: 38px;
    padding: 0;
    background-color: transparent;

    font-size: $fontSizeThree;
    justify-content: flex-start;
    border: none;
    transition:
      box-shadow 200ms,
      background-color 200ms,
      opacity 200ms;

    .icon-container {
      width: 18px !important;
      transform: translateY(2px);
    }

    span {
      transform: translateY(-1px);
    }

    &.new-game {
      .inner-content {
        span {
          color: #fff;
        }

        svg,
        path {
          fill: #7dbaef !important; // It's important to apply color on Case Battles Icon on Navbar
        }

        &:hover {
          svg,
          path {
            fill: white;
          }
        }
      }

      span:nth-child(2) {
        height: 24px;
        display: flex;
        align-items: center;
        transform: translateX(0px);
        transition: transform 0.1s ease-in-out;
        font-size: 10px;
        padding: 7px 8px;
        margin-left: auto;
        transform: translateX(-2px);
        text-transform: uppercase;

        border-radius: 4.222px;
        border: 1px solid #87c1f2;
        background: radial-gradient(
          76.33% 100% at 50% 100%,
          rgba(0, 119, 219, 0.5) 0%,
          rgba(0, 119, 219, 0.1) 100%
        );
        box-shadow: 0 0 6.111px rgba(0, 119, 219, 0.3);
      }

      &:hover {
        span:nth-child(2) {
          background: radial-gradient(
            76.33% 100% at 50% 100%,
            rgba(59, 36, 36, 0.5) 0%,
            rgba(59, 36, 36, 0.1) 70%,
            rgba(59, 36, 36, 0) 100%
          );
          // box-shadow: 0px 0px 15px 10px rgba(0, 119, 219, 0.3);
          transform: translateX(-6px);
          transition: transform 0.1s ease-in-out;
        }
      }

      &.active-item {
        span:nth-child(2) {
          background: radial-gradient(
            76.33% 100% at 50% 100%,
            rgba(59, 36, 36, 0.5) 0%,
            rgba(59, 36, 36, 0.1) 70%,
            rgba(59, 36, 36, 0) 100%
          );
          box-shadow: 0px 0px 15px 10px rgba(0, 119, 219, 0.3);
          transform: translateX(-6px);
          transition: transform 0.1s ease-in-out;
        }

        .inner-content {
          svg,
          path {
            fill: white !important;
          }
        }
      }
    }

    &.flyout-activation-item {
      transition:
        background-color 150ms,
        opacity 200ms;
    }

    .inner-content {
      align-items: center;
      flex-direction: row-reverse;
      gap: 10px;
      padding-left: 15px;

      span {
        transition: color 0.2s !important;
        color: #9aa0c1;
      }

      .icon-container {
        width: 14px;
        margin: 0;

        svg {
          path {
            transition: fill $tranTime;
            fill: $fontColorTwo;
          }
        }
      }

      .icon-container-nav {
        width: 18px;
        margin: 0;

        svg {
          path {
            transition: fill $tranTime;
            fill: $fontColorTwo;
          }
        }
      }
    }

    .icon-container svg path,
    circle {
      transition: fill $tranTime $easeInOut3;
      fill: $fontColorTwo;
    }

    &:hover {
      .inner-content {
        span {
          color: white;
        }

        .icon-container svg path,
        circle {
          fill: white !important;
        }
      }
    }

    &.active-item {
      border-radius: 6px;
      background: linear-gradient(0deg, #0077b8 0%, #0077db 100%);

      .inner-content {
        span {
          color: #fff;
        }

        .icon-container svg path,
        circle {
          fill: white;
        }
      }
    }
  }

  &.flyout-menu-item {
    width: 100%;
    min-width: 0;
    // border: 1px solid #fff;
    height: 42px;
    padding: 0;

    font-size: $fontSizeThree;
    justify-content: flex-start;
    background-color: transparent;
    border: none;
    transition:
      box-shadow 200ms,
      background-color 200ms,
      opacity 200ms;

    .inner-content {
      align-items: center;
      flex-direction: row-reverse;
      gap: 12px;
      padding-left: $padding / 2;

      span {
        transition: color 0.2s !important;
        color: $fontColorTwo;
      }

      .icon-container {
        width: 18px;
        margin: 0;

        svg {
          path {
            transition: fill $tranTime;
            // fill: $fontColorTwo;
          }
        }
      }
    }

    // .icon-container svg path,
    // circle {
    //   transition: fill $tranTime $easeInOut3;
    //   fill: $fontColorTwo;
    // }

    &:hover {
      background-color: $btn-fill-color;

      .inner-content {
        span {
          color: #ced1df;
        }

        .icon-container svg path,
        circle {
          fill: #ced1df;
        }
      }
    }

    &.active-item {
      background-color: $btn-fill-color;

      .inner-content {
        span {
          color: $gold;
        }

        .icon-container svg path,
        circle {
          fill: $gold;
        }
      }
    }
  }

  &.nav-section-toggle {
    background-color: transparent;
    justify-content: center;
    border: 1px solid transparent;
    height: 100%;
    padding: 14px 18px;
    transition: none !important;

    .inner-content {
      flex-direction: row-reverse;
      padding: 0;
      gap: 5px;

      span {
        color: #9aa0c1;
        font-size: 13px;
      }

      .icon-container {
        width: 14px;

        svg path {
          fill: #9aa0c1;
        }
      }
    }

    &:hover {
      .inner-content {
        span {
          color: #c0c4db;
        }

        .icon-container svg path,
        circle {
          fill: #c0c4db;
        }
      }
    }

    &.active-item {
      border-radius: 6px;
      border: 1.5px solid #3d9eef;
      background:
        radial-gradient(
          100% 100% at 50% 100%,
          #0077db 0%,
          rgba(0, 94, 173, 0.1) 100%
        ),
        #0b5cb4;
      box-shadow:
        0px 0px 8px 0px rgba(0, 119, 219, 0.2),
        0px 0px 12px 0px rgba(255, 255, 255, 0.35) inset;

      .inner-content {
        span {
          color: $fontColor;
        }

        .icon-container svg path,
        circle {
          fill: $fontColor;
        }
      }
    }
  }

  &.round-add-btn {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: #5b638d8c;
    border-color: #69719d;

    svg path {
      fill: #9aa0c1;
    }

    &.round-add-btn-small {
      width: 60px;
      height: 60px;
    }
  }

  &.icon-case-btn {
    .icon-container {
      width: 15px !important;

      svg {
        path {
          fill: $secondaryFontColor;
        }
      }
    }
  }

  &.toggle-btn {
    background-color: transparent;
    justify-content: center;
    border: 2px solid transparent;
    // height: 100%;
    background-color: #1a203d;
    // min-width: 112px;
    // flex-grow: 1;
    width: 33%;

    .inner-content {
      flex-direction: row-reverse;
      padding: 0;
      gap: 5px;

      span {
        color: $font-color-two;
        font-size: 13px;
        font-weight: 600;
      }

      .icon-container {
        svg path {
          fill: #5e679e;
        }

        // width: 15px;
      }
    }

    &:hover {
      .inner-content {
        span {
          color: #ced1df;
        }

        .icon-container svg path,
        circle {
          fill: #ced1df;
        }
      }
    }

    &.regular-toggle {
      &.active-item {
        background: #ed2b4e;
      }
    }

    &.team-toggle {
      &.active-item {
        background: #a586e3;
      }
    }

    &.group-toggle {
      &.active-item {
        background: #85f074;

        .inner-content {
          span {
            color: #3f3f3f;
          }
        }
      }
    }

    &.active-item {
      // background: $primary-fill-color;
      // border: 2px solid $primary-stroke-color;

      .inner-content {
        span {
          color: $fontColor;
        }

        .icon-container svg path,
        circle {
          fill: $fontColor;
        }
      }
    }

    @media (max-width: $breakPointThree) {
      width: 100%;
    }
  }

  &.mini-version {
    justify-content: center;

    &.active-item {
      border-radius: 6px;
      background: #171d3b;

      .inner-content {
        span {
          color: #fff;
        }

        .icon-container svg path,
        circle {
          fill: white;
        }
      }
    }

    &:hover {
      background-color: #171d3b;
    }

    .inner-content {
      display: flex;

      padding-left: 0;

      span {
        display: block;
        position: fixed;
      }
    }
  }

  &.quick-links {
    width: 160px;

    min-width: 0;
    // border: 1px solid #fff;
    background-color: $primaryBackgroundColor;
    height: 50px;
    font-size: $fontSizeFour;
    justify-content: center;
    border-radius: 50px;

    .inner-content {
      align-items: center;
      flex-direction: row-reverse;
      gap: 5px;

      .icon-container {
        width: 25px;
        margin: 0;
      }
    }

    &.nav-section-toggle {
      // border: 1px solid #fff;
      background: linear-gradient(
        to left,
        rgba(38, 46, 58, 0),
        rgba(27, 35, 45, 1)
      );
    }
  }

  //Plinko-------------------
  &.plinko-risk {
    width: 33%;
  }

  &.row-count {
    display: inline-block;

    &.active {
      background-color: $primaryBackgroundColor;
    }
  }

  //Plinko-------------^^^^^^

  //Sidebar buttons------------------------------
  &.betting {
    height: 60px;
    padding: 0;
  }

  &.adding-bet-btns {
    min-width: auto;
    width: 45px;
    height: 28px;
    border-radius: 6px;
    padding: 0;
    background-color: $fifthBackground;
    border: 1px solid $fifthBackground;

    .inner-content {
      span {
        font-size: 12px;
        color: $fontColorSeven;
        line-height: 0em;
      }
    }
  }

  &.adding-bet-btns-roulette {
    min-width: auto;
    width: 45px;
    height: 28px;
    border-radius: 6px;
    padding: 0;

    border: none;
    border-radius: 6px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.07) 100%
    );

    .inner-content {
      span {
        font-size: 12px;
        color: $fontColorSeven;
        line-height: 0em;
      }
    }
  }

  //Crash Button styling
  &.crash-up-arrow,
  &.crash-down-arrow {
    min-width: auto;
    width: 45px;
    height: 30px;
    padding: 0;
    // border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-container {
      margin: 0;
      // margin-bottom: 3px;
    }
  }

  &.crash-up-arrow {
    .icon-container {
      margin-bottom: 0px;
      // margin-top: 3px;
      transform: rotate(180deg);
    }
  }

  //------Roulette side buttons
  //The rest of the styling for this button is in roulette stylesheet
  &.roulette {
    min-width: 0;
    height: 50px;
    // width: 33%;

    &.r-red {
      background-color: #90112d;
    }

    &.r-green {
      background-color: #1f7e19;
    }

    &.r-black {
      background-color: #1c2344;
    }

    padding-bottom: 10px;
    position: relative;

    &:hover {
      div {
        bottom: 1px;
      }
    }

    &.active-btn {
      div {
        bottom: 0;
      }
    }

    &.de-active {
      pointer-events: none !important;
      opacity: 0.5;
    }
  }

  //------------------------------------Sidebar btns ^^

  //CHAT-------------------------------------------CHAT

  &.inline-btn-display {
    display: inline-block;
    padding: 0;
    // border: 1px solid #fff;
    height: auto;
    width: auto;
    // padding-right: 5px;
    min-width: 0;
    text-align: left;
    background-color: transparent;
    border: none;
    margin-top: 3px;
    border-radius: 0;

    .inner-content {
      font-size: 14px;
      gap: 10px;
      flex-direction: row-reverse;

      svg {
        fill: $gold;
      }

      path {
        fill: $gold;
      }
    }
  }

  &.challenge-username {
    background-color: transparent;
    padding: 0px !important;
    justify-content: center;
    margin-bottom: 2.2px;
    display: block;
    font-size: 13px !important;

    span {
      transition: color 0.2s !important;
      font-weight: 500;
      font-size: 13px !important;

      // margin-left: 4px;
      &:hover {
        color: #e0e2eb !important;
      }
    }
  }

  &.chat-user-display {
    padding: 0;
    height: auto;
    width: auto;
    padding-right: 6px;
    min-width: 0;
    text-align: left;
    background-color: transparent;
    border: none;
    border-radius: 0;
    margin-left: 0px;
    margin-top: 2px; // duplicate style of diamond and gold avatar.

    .inner-content {
      font-size: 13px;
      font-weight: 500;
      gap: 10px;
      flex-direction: row-reverse;
      //align-items: baseline;
      margin-top: 0px;

      svg {
        fill: $gold;
      }

      path {
        fill: $gold;
      }
    }

    &.chat-username {
      span {
        margin-top: 0px;
        transition: color 0.2s !important;
        font-weight: 600;

        // margin-left: 4px;
        &:hover {
          color: #e0e2eb !important;
        }
      }

      &.rainbet-support {
        span {
          transition: color 0.2s !important;
          margin-left: 4px;
          color: #ffde30;
          margin-top: -1px;

          &:hover {
            color: #ffe148 !important;
          }
        }
      }

      &.rainbet-admin {
        span {
          // margin-left: 4px;
          transition: color 0.2s !important;
          color: #fff;
          margin-top: -1px;

          &:hover {
            color: #0889f5 !important;
          }
        }
      }

      &.rainbet-moderator {
        span {
          transition: color 0.2s !important;
          // margin-left: 4px;
          width: 100%;
          color: #5dd750;
          margin-top: -2px;
          font-weight: 600;

          &:hover {
            color: #5dd750 !important;
          }
        }
      }

      &.logged-user {
        span {
          color: #ffb300;

          &:hover {
            color: #ffb300 !important;
          }
        }
      }

      &.user-profile-nospace {
        padding-right: 0px;
      }
    }

    &.wins-feed {
      .inner-content {
        .icon-container {
          height: 20px;
        }

        span {
          // font-family: $primaryFont;
          font-size: 13px;
        }

        svg {
          fill: $primary-stroke-color;
        }

        path {
          fill: $primary-stroke-color;
        }
      }
    }
  }

  &.lang-select {
    // background-color: $;
    // width: 150px;
    width: 100%;
    padding: 15px;
    justify-content: flex-start;

    .inner-content {
      padding-left: 25px;

      span {
        color: #ced1df;
        font-size: 13px;
      }
    }

    .value-icon {
      width: 16px;
    }
  }

  &.chat-select {
    justify-content: flex-start;
    max-width: 140px;
    width: 100%;
    padding: 12px 16px;
    height: 41px;
    font-size: 13px;

    border-radius: 5.684px;
    border: 0.947px solid #383e5e;
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.2) 0%,
      rgba(94, 103, 158, 0.2) 100%
    );

    .inner-content {
      width: 100%;
      justify-content: space-between;
    }
  }

  &.mini-class-lang {
    padding: 18px;

    .arrow-icon-container {
      display: none;
    }

    .inner-content {
      display: none;
    }

    .value-icon {
      left: 10px;
    }
  }

  // &.chat-username {
  //   span {
  //     transition: color .2s!important;
  //     margin-left: 4px;
  //     &:hover {
  //       color: #e0e2eb !important;
  //     }
  //   }
  //   &.rainbet-support {
  //     span {
  //       transition: color .2s!important;

  //       color: #FFDE30;
  //       &:hover {
  //         color: #ffe148 !important;
  //       }
  //     }
  //   }
  //   &.rainbet-admin {
  //     span {
  //       transition: color .2s!important;

  //       color: #0077DB;
  //       &:hover {
  //         color: #0889f5 !important;
  //       }
  //     }
  //   }
  // }

  //^^^^^^^^^^^^^^CHAT^^^^^^^--------------------------

  &.wallet-amount {
    height: 42px;

    &.in-play .inner-content {
      padding-left: 0px;
    }

    // padding: 8px;

    .inner-content {
      width: 100%;
      justify-content: space-between;
      gap: 10px;
    }

    @media (max-width: $breakPointTwo) {
      // height: 20px;
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;

      .value-icon {
        left: 6px;
      }

      .inner-content {
        gap: 0;
      }
    }
  }

  @media (min-width: 390px) {
    &.wallet-amount {
      display: flex;
    }
  }

  &.rounded {
    border-radius: 30px;
  }

  &.purple {
    background-color: $purple-one;
    color: $dark-one;

    &:hover {
      background-color: #d0cee8;
    }
  }

  &.yellow {
    background-color: $yellow;
  }

  &.blue {
    background-color: $primary-fill-color;
    border: 2px solid $primary-stroke-color;

    &:hover {
      box-shadow: 0px 0px 22px 0px rgba(0, 56, 255, 0.25);
    }
  }

  &.wallet-button {
    border-radius: 6px;
    border: 1px solid #7dd934;
    background:
      radial-gradient(
        100% 100% at 50% 100%,
        #5fdb00 0%,
        rgba(75, 173, 0, 0.3) 100%
      ),
      #3e7b0f;
    box-shadow: 0px 0px 22px 0px rgba(100, 180, 37, 0.25);
  }

  &.green {
    // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    position: relative;
    background-color: $secondary-fill-color;
    color: $fontColor;
    border: 2px solid $secondary-stroke-color;

    // .gradient-item {
    //   background: linear-gradient(96.17deg, #8efe36 9.56%, #00bd35 95.97%);
    //   // @include gradient(80deg,$lightGreen, $green);
    // }

    &:hover {
      box-shadow: 0px 2px 12px rgba(0, 189, 53, 0.3);
    }

    &.claim-button {
      padding: 10px 20px;

      .inner-content {
        span {
          font-size: 13px;
        }
      }
    }
  }

  &.header-btn {
    padding-left: 20px;
    padding-right: 20px;
    height: 42px;
    border-width: 1px;
    text-wrap: nowrap;

    @media (max-width: $breakPointOne) {
      height: 40px;
    }

    @media (max-width: 500px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &.gold {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    position: relative;
    background-color: $gold;
    color: $fontColor;
    border: none;
    border: 1px solid #ffffff15;

    .gradient-item {
      background: linear-gradient(149.01deg, #f1bb1c 20.89%, #ff8d01 84.15%);
    }

    &:hover {
      box-shadow: 0px 2px 12px rgba(216, 182, 14, 0.3);
    }
  }

  &.pink {
    width: 100%;
    position: relative;

    @include gradient(80deg, $darkPurple, $darkPurple);
    // background: linear-gradient(118.18deg, #FF42F7 14.06%, #FF42F7 78.59%);
    color: $fontColor;

    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

    border: 1px solid #ffffff14;
    // border-color: transparent;

    .gradient-item {
      border-radius: $radius;
      opacity: 1;
      background: linear-gradient(118.18deg, #8a2eff 14.06%, #ff42f7 78.59%);

      left: -1px;
      right: -1px;
      bottom: -1px;
      top: -1px;
      //    border: 2px solid #ffffff25;
    }

    &:hover {
      .gradient-item {
        opacity: 0;
      }

      // background: linear-gradient(118.18deg, #8A2EFF 14.06%, #FF42F7 78.59%);
      box-shadow: 0px 2px 12px rgba(138, 46, 255, 0.4);
    }
  }

  &.dark {
    background-color: $primaryBackgroundColor;
    border: none;
  }

  &.tab {
    border: 1px solid transparent;
    background-color: transparent;
    padding: 10px 20px;
    transition: none;
    border-radius: $borderRadius;

    &.has-transition {
      transition:
        box-shadow 800ms cubic-bezier(0.79, 0.01, 0.14, 0.99),
        background-color 500ms cubic-bezier(0.79, 0.01, 0.14, 0.99),
        opacity 500ms cubic-bezier(0.79, 0.01, 0.14, 0.99);
    }

    &:hover {
      background: none;
    }

    .inner-content span {
      color: #5b628c;
      transition: color 0.2s;

      &:hover {
        background: none;
        color: #c0c4db;
      }
    }

    &.live-tab {
      padding: 10px 20px;
      border-radius: 4px;
      // border: none;

      // &:hover {
      //   background: none;
      // }
      .gradient-item {
        border: none;
        // background: linear-gradient(118deg, #8A2EFF 14.06%, #FF42F7 78.59%);
        background: none;
      }

      &:hover {
        box-shadow: none;

        .inner-content span {
          // color: $fontColor;
          color: #c0c4db;
        }
      }

      &.active-item {
        // border-radius: 10px;
        border: 1px solid $primary-stroke-color;
        background: $primary-fill-color;
        // box-shadow: 0px 0px 22px 0px rgba(0, 56, 255, 0.25);

        &:hover {
          .inner-content span {
            // color: $fontColor;
            color: #fff;
          }
        }
      }
    }

    .gradient-item {
      opacity: 0;
      @include gradient(80deg, $darkPurple, $pink);
      border: 1px solid #ffffff10;
    }

    &:hover {
      // .inner-content span {
      //   // color: $fontColor;
      //   color: #E9C8F4;
      // }
      svg {
        path {
          fill: #fff;
        }
      }

      .gradient-item {
        opacity: 1;
      }

      box-shadow: 0px 0px 12px rgba(138, 46, 255, 0.5);
    }

    &.active-item {
      background-color: $darkPurple;

      box-shadow: 0px 0px 12px rgba(138, 46, 255, 0.1);

      .gradient-item {
        opacity: 1;

        // @include gradient(80deg, $darkPurple, $pink);
      }

      .inner-content span {
        color: $fontColor;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }

    &.active-button {
      // border-radius: 10px;

      border: 1px solid $primary-stroke-color;
      background: $primary-fill-color;
      // box-shadow: 0px 0px 22px 0px rgba(0, 56, 255, 0.25);
      // box-shadow: 0px 6px 22px 0px rgba(254, 66, 248, 0.14);
      transition: none;

      // background-color: $darkPurple;
      // box-shadow: 0px 0px 12px rgba(138, 46, 255, 0.5);
      // border: 1px solid #ffffff15;
      // border-image-source: linear-gradient(106.04deg, #A055FF 12.59%, #FF51F9 75.19%);

      .gradient-item {
        opacity: 1;
        // @include gradient(80deg, $darkPurple, $pink);
        // background: linear-gradient(118.18deg, #8a2eff 14.06%, #ff42f7 78.59%);

        // @include gradient(118deg,$darkPurple, $pink);
      }

      .inner-content span {
        // color: $fontColor;
        color: $fontColor;
      }

      &:hover {
        .inner-content span {
          // color: $fontColor;
          color: #fff;
        }
      }
    }
  }

  &.full-width-tab {
    background-color: $btnFill;
  }

  &.active-item {
    background-color: $white;
    color: $dark-one;
  }

  &.full-width {
    width: 100%;
  }

  &.half-width {
    width: 50%;

    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }

  &.mid-height {
    height: 50px !important;
  }

  &.small-height {
    height: 40px;
  }

  &.has-value-rewards {
    width: 42px;
    height: 42px;
    border-radius: 6px;
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.15) 0%,
      rgba(94, 103, 158, 0.12) 100%
    );

    box-shadow: inset 0px 0px 2px 0px rgba(124, 131, 177, 0.15);
    /* Inner shadow effect */
    border: 1px solid rgba(124, 131, 177, 0.15);
    /* Required for border-image */

    .icon-container {
      justify-content: center;
      width: auto;

      svg {
        width: auto;
      }
    }

    &.active-item {
      background-color: $btn-fill-hover-color;
    }

    @media (max-width: $breakPointOne) {
      height: 40px;
      width: 40px;
    }

    &:hover {
      border-radius: 6px;
      background:
        linear-gradient(#222846, #222846) padding-box,
        linear-gradient(to right, #6b7198, #656c96) border-box;
      border-image: linear-gradient(180deg, #6e759d, #515a89) 1;
      box-shadow: inset 0px 0px 2px 0px #7d83a8;
      /* Inner shadow effect */
      border: 1.2px solid #7d83a8;
      /* Required for border-image */
    }
  }

  &.square {
    width: 42px;
    height: 42px;
    border-radius: 6px;
    border: 1px solid #363c5e;
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.15) 0%,
      rgba(94, 103, 158, 0.12) 100%
    );
    transition: all 0.3s ease;

    .icon-container {
      justify-content: center;
      width: auto;

      svg {
        width: auto;
      }
    }

    &:hover {
      border-radius: 6px;
      background:
        linear-gradient(#222846, #222846) padding-box,
        linear-gradient(to right, #6b7198, #656c96) border-box;
      border-image: linear-gradient(180deg, #6e759d, #515a89) 1;
      box-shadow: inset 0px 0px 2px 0px #7d83a8;
      /* Inner shadow effect */
      border: 1.2px solid #7d83a8;
      /* Required for border-image */
    }

    &.active-item {
      background-color: $btn-fill-hover-color;
    }

    @media (max-width: $breakPointOne) {
      height: 40px;
      width: 40px;
    }
  }

  &.mobile-nav {
    & > * {
      background-color: transparent;
      border: none !important;
    }
  }

  &.deposit {
    width: 100px;
    border: 1px solid #7dd934;

    @media (max-width: $breakPointOne) {
      display: none;
    }

    &.active-item {
      background-color: inherit;
    }
  }

  &.mobile-deposit {
    display: none;

    @media (max-width: $breakPointOne) {
      display: flex;
      height: 40px;
      width: 40px;
      padding: 0;
    }
  }

  &.mobile-menu-button {
    span {
      @media (max-width: 450px) {
        color: rgba(232, 229, 255, 0.75) !important;
      }
    }
  }

  // @media (max-width: $breakPointOne) {
  //     width: 36px;
  //     height: 36px;
  //     padding: 0;
  // }

  &.large-font {
    .inner-content span {
      font-size: 18px;
    }
  }

  &.drop-down-space {
    .inner-content {
      gap: 5px;
    }
  }

  //Drop down styling
  &.drop-down {
    background-color: transparent;
    color: $dark-one;
    padding: 13px;
    // padding-left: 20px;
    // padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    // border: none;
    border-top: 1px solid #ffffff05;
    border-left: none;
    border-right: none;
    border-bottom: none;
    transition: background-color 0.2s ease-in-out;

    &.profile-drop-btn {
      text-align: left;
      justify-content: flex-start;
      align-items: center;
      padding: 8px 10px;
      border: none;
      gap: 10px;
      margin-bottom: 8px;
      border-radius: 6px;
      transition: background-color 0.2s ease-in-out;

      .inner-content {
        flex-direction: row-reverse;
        gap: 10px;
      }

      &:hover {
        background-color: #0077b8;
        /* Use a solid color for the transition */

        svg {
          path {
            fill: white;
          }
        }
      }
    }

    &:first-child {
      border-top: none;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .inner-content {
      font-size: 13px;
      text-align: center;

      span {
        color: $white;
      }
    }

    &:hover {
      color: $white;
      background-color: rgba(13, 15, 27, 0.15);
    }
  }

  &.timer-size {
    .inner-content {
      font-size: 12px;
    }
  }

  &.claim-dropdown {
    border: 1px solid #2099ff;
    background: linear-gradient(0deg, #0098eb 0%, #0077db 100%);
    border-radius: 6px;
    padding: 10px 18px;

    &,
    * {
      transition: none;
    }

    span {
      font-size: 12px;
    }

    &.timer,
    &.disabled {
      border: 1px solid transparent;
      padding: 10px 14px;
      border-radius: 6px;
      background: linear-gradient(
        180deg,
        rgba(94, 103, 158, 0.2) 0%,
        rgba(94, 103, 158, 0.2) 100%
      );
    }
  }

  &.crypto-display {
    justify-content: flex-start;

    .inner-content {
      padding-left: 35px;
    }

    //  width: 150px;
  }

  &.username {
    width: 150px;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 14px 0px 10px;
    height: 42px;
    border-radius: 6px;
    border: 1px solid #363c5e;
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.1) 0%,
      rgba(94, 103, 158, 0.1) 100%
    );

    .inner-content {
      width: 100%;
      justify-content: space-between;
      padding-bottom: 10px;

      span {
        padding-left: 40px;
        font-size: 12px;
        color: $sub-heading-color;
      }

      .icon-container {
        margin-right: 5px;
      }
    }

    .value-icon {
      width: 25px;
      height: 30px;

      svg {
        width: 100%;
      }
    }

    &.no-rank {
      .inner-content {
        width: 100%;
        justify-content: space-between;
        padding-bottom: 10px;

        span {
          padding-left: 7px;
        }
      }
    }

    &.active-button {
      background-color: $btn-fill-active-color;
    }
  }

  &.interac-withdraw {
    .icon-container {
      width: 90px !important;
    }
  }

  &.method-select-withdraw {
    flex: 1 1 calc(33% - 5px);
    max-width: calc(33% - 5px);
    height: 56px;

    border: 1px solid #5e679e44;
    padding: 20px;

    background: radial-gradient(ellipse at 50% 150%, #5e679e40, #5e679e04);

    .gradient-item {
      opacity: 0;
      transition: opacity 0.4s ease-in-out;

      background: radial-gradient(ellipse at 50% 150%, #0077db, #0078db16);
    }

    &__specific-selected {
      border: 1px solid #0077db;

      .gradient-item {
        opacity: 0.5;
      }
    }

    @media (max-width: $breakPointTwo) {
      max-width: calc(50% - 5px);
      max-width: 100%;
      flex: 1 1 calc(50% - 5px);
    }

    .inner-content {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: flex-end;
      // align-items: flex-start;
      align-items: center;

      span {
        width: 100%;
      }

      .icon-container {
        width: 90px;
        margin: 0;
      }
    }

    &.active-item {
      background-color: $activeBtn !important;
    }

    &.rs-method {
      // background-color: #262c52;
      width: 100%;
      max-width: none;

      .inner-content {
        align-items: center;

        .icon-container {
          width: 32px;
          margin: 0;
        }
      }

      // &:hover {
      //   .inner-content {
      //     span {
      //       color: #98a1c7;
      //     }
      //   }
      // }
    }

    &:hover {
      background-color: #23294d42;
    }

    &.justify-center {
      .inner-content {
        justify-content: center;
      }
    }
  }

  &.method-select {
    flex: 1 1 calc(33% - 5px);
    max-width: calc(33% - 5px);
    height: 56px;

    border: 1px solid #5e679e44;
    padding: 20px;

    background: radial-gradient(ellipse at 50% 150%, #5e679e40, #5e679e04);

    .gradient-item {
      opacity: 0;
      transition: opacity 0.4s ease-in-out;

      background: radial-gradient(ellipse at 50% 150%, #0077db, #0078db16);
    }

    &__specific-selected {
      border: 1px solid #0077db;

      .gradient-item {
        opacity: 0.5;
      }
    }

    @media (max-width: $breakPointTwo) {
      max-width: calc(50% - 5px);
      max-width: 100%;
      flex: 1 1 calc(50% - 5px);
    }

    .inner-content {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: flex-end;
      // align-items: flex-start;
      align-items: center;

      span {
        width: 100%;
      }

      .icon-container {
        width: 25px;
        margin: 0;
      }
    }

    &.active-item {
      background-color: $activeBtn !important;
    }

    &.rs-method {
      // background-color: #262c52;
      width: 100%;
      max-width: none;

      .inner-content {
        align-items: center;

        .icon-container {
          width: 32px;
          margin: 0;
        }
      }

      // &:hover {
      //   .inner-content {
      //     span {
      //       color: #98a1c7;
      //     }
      //   }
      // }
    }

    &:hover {
      background-color: #23294d42;
    }

    &.justify-center {
      .inner-content {
        justify-content: center;
      }
    }
  }

  &.open-case span {
    font-size: 14px;
  }

  &.active-button {
    // box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.849);
    background-color: $btnActiveFill;

    .inner-content {
      .icon-container {
        transform: rotate(180deg);
      }
    }
  }

  &.icon-left {
    .inner-content {
      flex-direction: row-reverse;

      .icon-container {
        margin: 0;
        margin-right: 10px;
      }
    }
  }

  &:hover {
    .inner-content {
      span {
        color: $fontColor;
      }
    }
  }

  //Dice btns----------------------------

  &.dice-toggle {
    padding-left: 30px;
    padding-right: 30px;

    .gradient-item {
      opacity: 0;
    }

    background-color: $btnFill;

    .inner-content {
      span {
        color: $fontColorTwo;
      }
    }

    &.active-button {
      .gradient-item {
        opacity: 1;
      }

      .inner-content {
        span {
          color: $fontColor;
        }
      }

      // box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
      // background-color: $rouletteGreen;
    }

    &:hover {
      .inner-content {
        span {
          color: $fontColor;
        }
      }

      .gradient-item {
        opacity: 1;
      }
    }
  }

  &.fair {
    // position: absolute;

    background-color: #1a1d3d !important;
    border-radius: 6px;
    border-color: transparent;
    padding: 10px;
    min-width: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  &.new-fair {
    background-color: transparent !important;
    border: none !important;
    .gradient-item {
      opacity: 0;
    }
  }

  &.home-links {
    width: 33%;
    border: 1px solid #fff;
  }

  //Dice-----^^^^
  &.emote-select {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #3b426c;
    background: linear-gradient(
      180deg,
      rgba(94, 103, 158, 0.2) 0%,
      rgba(84, 92, 141, 0.2) 100%
    );
    margin-right: 12px;
  }

  &.close-chat {
    background-color: transparent;
    border: none;
    border-radius: 18.065px;
    height: 15px;
    width: 15px;
    border: 0.903px solid #383e5e;
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.2) 0%,
      rgba(94, 103, 158, 0.2) 100%
    );
  }

  &.square-inline {
    min-width: 0;
    height: auto;
    padding: 3px;
    border: none;
    background-color: transparent;

    .inner-content .icon-container {
      margin: 0;
      display: flex;
      align-items: center;

      svg {
        width: 13px;
        transform: translateY(-1px);
      }
    }
  }

  &.mini {
    min-width: 0;
    height: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    // width: 44px;
    padding-left: 15px;
    padding-right: 15px;

    .inner-content span {
      font-size: 13px;
    }
  }

  &.expand {
    width: 100%;
    height: auto;

    border: none;
    padding: $padding;
    padding-top: 15px;
    padding-bottom: 15px;
    height: auto;
    transition:
      radius 500ms $easeInOut3,
      background-color 300ms $easeInOut3;
    justify-content: space-between;
    display: flex;

    &.active-button {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: $lightBackgroundColor;
    }

    .inner-content {
      justify-content: space-between;
      width: 100%;
    }

    &:hover {
      background-color: $lightBackgroundColor;
    }
  }

  &.meta-steam {
    padding: 12px;
    min-width: 0;
    width: 58px;
    height: 58px;
    border-radius: 6px;
    border: 1px solid #282f58;
    background:
      radial-gradient(
        76.33% 100% at 50% 100%,
        #262c52 0%,
        rgba(38, 44, 82, 0.1) 100%
      ),
      rgba(38, 44, 82, 0.5) !important;

    .icon-container {
      margin: 0;
      width: 100%;
    }
  }

  &.meta-steam-register {
    padding: 15px;
    min-width: 0;
    width: 122px;
    height: 50px;
    border-radius: 6px;
    border: 2px solid #282f58;
    background:
      radial-gradient(
        76.33% 100% at 50% 100%,
        #262c52 0%,
        rgba(38, 44, 82, 0.1) 100%
      ),
      rgba(38, 44, 82, 0.5) !important;

    .icon-container {
      margin: 0;
      width: 100%;
      height: 22px;
    }
  }

  &.mid {
    padding: 10px;
    min-width: 100px;

    .inner-content {
      font-size: 13px;
    }
  }

  &.inline {
    background-color: transparent;
    border: none;
    min-width: 0;
    height: auto;
    // padding-top: 2px;
    // padding-bottom: 2px;
    width: auto;
    // display: inline-block;
    border-radius: 0;
    display: inline;

    .inner-content {
      display: inline;
    }

    span {
      // font-family: $mainFont;
      // padding: 2px;
      display: inline;
      line-height: 0.5em;
      color: #fff;
    }
  }

  &.message-submit {
    padding: 8px;
    border-radius: 6px;
    border: 1.5px solid #66b9ff;
    background: #0077db;

    .inner-content {
      .icon-container {
        margin: 0;
      }
    }
  }

  &.ds-submit {
    cursor: not-allowed;
    pointer-events: none !important;
    opacity: 0.2;

    padding: 8px;
    border-radius: 6px;
    border: 1.5px solid #66b9ff;
    background: #0077db;

    .inner-content {
      .icon-container {
        margin: 0;
      }
    }

    // &:hover {
    //   .inner-content .icon-container svg path {
    //     fill: rgb(197, 148, 23);
    //   }
    // }
  }

  &.icon-only {
    min-width: 0;
    height: auto;
    padding: 1px;
    border: none;
    background-color: transparent;
    border-radius: 0;

    .inner-content .icon-container {
      margin: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.hide-icon {
    &:hover {
      .inner-content .icon-container {
        svg path {
          fill: rgb(176, 9, 9);
        }
      }
    }
  }

  &.icon-background {
    min-width: 0;
    // height: auto;
    padding: 15px;
    border: none;
    border-radius: $radius;
    background-color: $thirdBackground;
    border: 1px solid #1d2447;

    .inner-content .icon-container {
      margin: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.full-screen {
    width: 45px;
    height: 45px;
    transition:
      transform 100ms $easeInOut3,
      box-shadow 100ms $easeInOut3;
    position: relative;

    .inner-content {
      .icon-container {
        div {
          height: 23px;

          svg {
            width: 23px;
            height: 23px;
          }
        }
      }
    }

    &:hover {
      border-radius: 22px;
      border: 2px solid #1d2242;
      background: #1d2242;

      .inner-content {
        .icon-container {
          div {
            svg {
              fill: #6c75ac;

              path {
                fill: #6c75ac;
              }
            }
          }
        }
      }
    }

    &.theather-active {
      border-radius: 22px;
      border: 2px solid #1d2242;
      background: #1d2242;

      .inner-content {
        .icon-container {
          div {
            svg {
              fill: #6c75ac;

              path {
                fill: #6c75ac;
              }
            }
          }
        }
      }
    }

    // @media screen and (max-width: 680px) {
    //   &.full-screen {
    //     width: 30px;
    //     height: 30px;
    //     margin-left: -10px;
    //     margin-right: 10px;
    //     margin-bottom: 4px;
    //     .inner-content {
    //       .icon-container {
    //         div {
    //           height:20px;
    //           svg {
    //             width:20px;
    //             height:20px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    &.mobile-three {
      &.full-screen {
        width: 30px;
        height: 30px;
        margin-left: -10px;
        margin-right: 10px;
        margin-bottom: 4px;

        .inner-content {
          .icon-container {
            div {
              height: 20px;

              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }

  &.load-more {
    margin-top: 40px;
    padding-left: 75px;
    padding-right: 75px;
    margin-left: auto;
    margin-right: auto;

    .inner-content span {
      font-size: 14px;
    }
  }

  &.small-text {
    .inner-content span {
      font-size: 13px;
    }
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.plain {
    .inner-content {
      span {
        color: $fontColor;
        font-size: 13px;
      }
    }
  }

  &.modal-return {
    // background-color: transparent !important;
    border: none !important;
    width: fit-content;
    padding: 0px !important;

    background-color: transparent !important;

    .inner-content {
      gap: 2px;

      span {
        color: $fontColorTwo;
        font-size: 13px;
      }

      .icon-container {
        margin: 0;
        width: 16px !important;
      }
    }

    &:hover {
      .icon-container {
        svg path {
          fill: #9aa1c5;
        }
      }

      .inner-content {
        span {
          color: #9aa1c5;
        }
      }
    }
  }

  &.low-padding {
    padding: 10px;
  }

  &.reward-dropdown {
    background-color: $btnFillThree;
    padding: 12px;
    width: 100% !important;

    span {
      font-size: 12px;
    }
  }

  &.bottom-padding {
    margin-bottom: $padding;
  }

  &.stacked {
    background-color: transparent;
    border: none;

    span {
      font-size: 12px;
      color: $fontColorTwo;
    }

    .inner-content {
      // align-items: normal;
      gap: 10px;
      flex-direction: column-reverse;

      .icon-container {
        width: 15px;
        height: 15px;

        svg {
          path {
            fill: rgba(232, 229, 255, 0.75);
          }
        }
      }
    }
  }

  &.battle-lobby {
    height: 42px;
    width: 100%;
    max-width: 120px;

    .inner-content {
      flex-direction: row-reverse;

      .icon-container {
        margin: 0;
        margin-right: 5px;
      }
    }
  }

  &.battle-lobby-view {
    border: 1px solid #e8e5ff6a;
    background-color: #e8e5ff24;
  }

  &.add-cases-btn {
    background: radial-gradient(
      circle at bottom,
      rgba(38, 44, 82, 1),
      rgba(38, 44, 82, 0.1)
    );
  }

  &.stacked-notification {
    background-color: transparent;
    border: none;

    span {
      font-size: 12px;
      color: #e8e5ffbf;
    }

    .inner-content {
      // align-items: normal;
      gap: 10px;
      flex-direction: column;
      margin-top: 25px;

      .icon-container {
        width: 15px;
        height: 15px;
        display: none;

        svg {
          path {
            fill: $fontColorTwo;
          }
        }
      }
    }

    .value-icon {
      width: 17px;
      height: 17px;
      top: 13px;
      left: 27px;

      svg {
        path {
          fill: rgba(232, 229, 255, 0.75);
        }
      }
    }
  }

  &.rewards-dropdown {
    width: 100%;
    justify-content: flex-end;
    border: none;
    width: 42px;
    height: 42px;
    border: 1px solid #363c5e;
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.15) 0%,
      rgba(94, 103, 158, 0.12) 100%
    );
    border-radius: 6px;

    &:hover {
      border-radius: 6px;
      background:
        linear-gradient(#222846, #222846) padding-box,
        linear-gradient(to right, #6b7198, #656c96) border-box;
      border-image: linear-gradient(180deg, #6e759d, #515a89) 1;
      box-shadow: inset 0px 0px 2px 0px #7d83a8;
      /* Inner shadow effect */
      border: 1.2px solid #7d83a8;
    }

    .inner-content {
      span {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 11px;
      }

      gap: 7px;

      .icon-container {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  &.all-rewards {
    height: 42px;
    padding: 0px 28px;
    border-radius: 6px;
    transition: all 150ms ease-in-out;
    border: 1px solid #282c47;

    border-radius: 6px;
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.25) 0%,
      rgba(94, 103, 158, 0.25) 100%
    );

    &:hover {
      transition: all 150ms ease-in-out;
      // border: 1px solid #3e446b;
      background: linear-gradient(
        180deg,
        rgba(124, 131, 177, 0.35) 0%,
        rgba(94, 103, 158, 0.35) 100%
      );
    }
  }

  &.inline-settings {
    width: 130px;
  }

  &.home-banner-button {
    .inner-content {
      span {
        font-size: 18x;
      }
    }
  }

  &.home-banner-sports {
    border: 1px solid #ffffff10;
    background-color: transparent;

    .gradient-item {
      background: linear-gradient(
        112.17deg,
        #ff9939 6.42%,
        rgba(16, 85, 81, 0.24) 114.75%
      );
    }

    @media screen and (max-width: 769px) {
      .inner-content {
        font-size: 12px;
      }

      .icon-container {
        margin-right: 5px;

        svg {
          height: 16px;
        }
      }
    }
  }

  &.home-register {
    // height: 66px;
    // width: 200px;
    padding: 15px 35px;
    font-size: 18px;
    border-radius: 70px;

    .inner-content {
      gap: 10px;

      span {
        font-size: 16px;
      }

      .icon-container {
        width: 25px;
      }
    }

    // @media screen and (max-width: 769px) {
    //   height: 60px;
    //   // width: 160px;
    //   padding: 15px 25px;
    // }
    // @media screen and (max-width: 585px) {
    //   height: 50px;
    //   // width: 140px;
    //   padding: 15px 20px;
    //   .icon-container {
    //     display: none;
    //   }
    // }

    // drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  }

  &.header-search {
    border: 1px solid #363c5e;
    border-radius: 6px;
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.15) 0%,
      rgba(94, 103, 158, 0.12) 100%
    );

    &.no-border {
      border: none !important;
    }

    .icon-container {
      width: 24px;
      border-radius: 6px;

      svg path {
        fill: #9aa0c1;
      }
    }

    &:hover {
      .icon-container {
        svg path {
          fill: #c0c4db;
        }
      }

      border-radius: 6px;
    }

    @media (min-width: 520px) {
      height: 40px;
      width: 40px;
      display: flex;
    }
  }

  &.home-square {
    padding: 0px;
    height: fit-content;
    // width: 66px;
    // height: 66px;
    background-color: transparent;
    border: none;

    .icon-container {
      width: 30px;

      svg {
        height: auto;
        width: 100%;
      }
    }

    @media screen and (max-width: 585px) {
      height: 50px;
      width: 50px;
    }
  }

  &.color-two {
    .inner-content span {
      color: $fontColorTwo;
    }
  }

  &.color-three {
    .inner-content span {
      color: #c1c4dc;
    }
  }

  &.odds {
    width: 100%;

    .inner-content {
      > span {
        width: 100%;
      }

      span {
        // justify-content: space-between;
        color: #ced1df;
      }
    }
  }

  &.no-border {
    background-color: transparent;
    border: none;
    border-radius: 0;
  }

  &.edit-button {
    .inner-content span {
      // font-family: $primaryFont;
      color: $fontColorTwo;
    }
  }

  &.edit-height {
    height: 38px;
  }

  &.balance-drop-item {
    box-sizing: border-box;
    // width: 100%;
    padding: 15px 15px;
    border: none;

    border: 1px solid transparent;
    border-radius: $radius;
    justify-content: flex-start;

    background-color: $btn-fill-color;

    &:hover {
      background-color: #ffffff0d;
      // background-color: $btnActiveFill;
    }

    &.active-item {
      border: 1px solid #7dd934;
      background-color: #7ed93426;
    }

    &__history {
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &.notification-dropdown-button {
    border: none;
    background: #151a33;
    border-radius: 6px;

    &.no-padding {
      padding: 0;
      border-radius: 0;
    }

    &:hover,
    &:active {
      border-radius: 66px;
      border: 0px solid #282f53;
      background: #121730;
    }
  }

  &.notification-dropdown-button-active {
    border: 0px solid #282f53;
    background: #121730;
  }

  &.icon-space {
    .inner-content {
      gap: 10px;
    }
  }

  &.no-padding {
    padding: 0;
    border-radius: 0;
  }

  // Notification Dropdown
  &.notification-dropdown-close-button {
    border: none;
    background: #1e233c;
    margin: -4px -4px 0px 0px;

    &:hover {
      path {
        fill: #8888a8;
      }
    }

    .icon-container {
      width: 13px;
    }
  }

  &.left-align {
    width: auto;
    padding-left: 0px;
    justify-content: flex-start;
  }

  &.sports-book-home {
    transition:
      transform 300ms $easeInOut3,
      box-shadow 300ms $easeInOut3;
    // display: flex;
    width: 100%;

    flex-direction: column;
    // justify-content: center;
    gap: 20px;
    border-radius: 6px;
    border: 2px solid $btn-stroke-color;
    background: #262c52;
    padding: 20px;
    display: flex;
    gap: 15px;
    align-items: flex-start;
    max-height: 79px;

    &:hover {
      transform: translate(0px, -3px);
      cursor: pointer;
      box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.15);
    }

    .inner-content {
      flex-direction: row-reverse;
      gap: 15px;
      line-height: 1.25;
      width: 100%;
      justify-content: flex-end;

      span {
        justify-content: flex-start;
        text-align: left;
        color: #e8e5ff;
        font-size: 13px;
        font-style: normal;
        line-height: normal;
      }

      .icon-container {
        width: 100%;
        color: rgba(113, 121, 165, 1);
        // align-items: center;
        justify-content: center;
        border-radius: 6px;
        border: 2px solid $eightBackground;
        background: $eightBackground;
        padding: 8px;
        max-width: 40px;
        max-height: 40px;
        align-items: center;

        svg {
          width: 18px;
          height: 18px;
          fill: $inputLabelColorLight;

          path {
            fill: $inputLabelColorLight;
            width: 18px !important;
            height: 18px !important;
          }
        }
      }
    }
  }

  &.page-not-found {
    display: inline-flex;
    height: 44px;
    padding: 13px 31px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #b3ff79;
    background: linear-gradient(96deg, #8efe36 9.56%, #00bd35 95.97%);
    box-shadow: 0px 6px 22px 0px rgba(14, 196, 53, 0.22);
  }

  &.collect-button {
    span {
      font-size: 13px;
    }
  }

  &.sports-search-button {
    background-color: #1b213e;
    border: 1px solid #282f53;
    overflow: hidden;
    margin-top: 24px;
    margin-bottom: 10px;
    height: 44px;
    max-width: 220px;

    .inner-content {
      padding-left: 12px;
      gap: 5px;
    }
  }

  &.social-media-square-button {
    @media screen and (max-width: 769px) {
      padding: 10px;
      width: 40px;
      height: 40px;
    }
  }

  &.btn-language-odds {
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.2) 0%,
      rgba(94, 103, 158, 0.2) 100%
    );
    padding: 12px 16px;

    border-radius: 6px;
    border: 1px solid #313757;
    width: 100%;

    .inner-content {
      width: 100%;
      justify-content: space-between;
    }

    .value-icon {
      width: 16px;
    }
  }

  &.btn-language-odds-mobile {
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.2) 0%,
      rgba(94, 103, 158, 0.2) 100%
    );

    border-radius: 6px;
    border: 1px solid #313757;
    width: 100%;

    .inner-content {
      width: 100%;
      justify-content: space-between;
    }

    .value-icon {
      width: 16px;
    }
  }

  &.odds-language-selects {
    .inner-content {
      padding-right: 0px;
      padding-left: 30px;

      span {
        color: #ced1df;
        font-size: 13px;
      }
    }

    .value-icon {
      width: 16px;
      height: 16px;
    }
  }

  &.blue-button {
    border-radius: 6px;
    background: #0077db;
    padding: 12px 20px;
    text-transform: capitalize;
    color: #fff;
    line-height: normal;
    width: fit-content;
    // min-width: 180px;
    transition: color 0.2s;

    .inner-content {
      span {
        font-size: 13px;
        font-style: normal;
        // font-weight: 500;
      }
    }

    &:hover {
      background: #0a81e5;
    }

    &.promotion-active {
      pointer-events: none;
      max-height: 40px;
    }

    &.has-icon {
      padding: 12px 27px 12px 18px;
    }

    &.has-border {
      border-radius: 6px;
      border: 2px solid #2099ff;

      &:hover {
        border: 2px solid #1d93f6;
        background: #0073d4;
        box-shadow: 0px 0px 22px 0px rgba(0, 56, 255, 0.25);
      }
    }

    &.full-width {
      width: 100%;
      padding: 15px 0px;
    }

    &.modal-button {
      .inner-content {
        span {
          font-size: 14px;
          // font-family: $primaryFontMedium;
        }
      }
    }

    &.fifth-width {
      width: 50%;
    }

    &.small-font {
      .inner-content {
        span {
          font-size: 12px;
        }
      }
    }

    &.max-height-button {
      max-height: 40px;
    }
  }

  &.register-button {
    width: 100px;
    border: 1.5px solid #0077db;
    border-radius: 6px;
    border: 1.5px solid #0077db;
    background:
      radial-gradient(
        139.89% 135.63% at 50% 115%,
        #0077db 0%,
        rgba(38, 44, 82, 0.1) 100%
      ),
      linear-gradient(
        0deg,
        rgba(0, 119, 219, 0.3) 0%,
        rgba(0, 119, 219, 0.3) 100%
      ),
      #262c52;
    box-shadow: 0px 0px 15px 0px rgba(0, 119, 219, 0.4);

    &:hover::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("/Gifs/thunder.gif") center center no-repeat;
      background-size: cover;
      opacity: 0.25;
      width: 100%;
      z-index: 1;
    }

    & > * {
      position: relative;
      z-index: 2;
    }
  }

  &.grey-button {
    border-radius: 6px;
    background: #252c4f;
    padding: 12px 20px;
    text-transform: capitalize;
    width: fit-content;
    min-width: 130px;
    // min-width: 180px;
    transition: background-color 0.2s;

    .inner-content {
      span {
        color: #c1c4dc;
        font-size: 13px;
        font-style: normal;
        // font-weight: 500;
        line-height: normal;
      }
    }

    &:hover {
      background: #2a3155;
    }

    &.promotions-page {
      border-radius: 6px;
      border: 2px solid #4d5696;
      background: #3d457c;
      max-height: 40px;

      .inner-content {
        span {
          color: #fff;
        }
      }

      &:hover {
        border: 2px solid #464f8a;
        background: #3b4277;
      }
    }

    &.cancel-button {
      border-radius: 6px;
      border: 2px solid #4d5696;
      background: #3d457c;

      .inner-content {
        span {
          color: #fff;
        }
      }

      &:hover {
        border: 2px solid #464f8a;
        background: #3b4277;
      }
    }

    &.not-clickable {
      pointer-events: none;

      &:hover {
        background: #252c4f;
      }
    }

    &.cancelled-button {
      border-radius: 6px;
      border: 2px solid #4d5696;
      background: #3d457c;
      padding: 8px 30px 8px 20px;

      .inner-content {
        span {
          color: #7680c5;
        }
      }
    }

    &.not-eligible-button {
      border-radius: 6px;
      border: 2px solid #4d5696;
      background: #3d457c;
      padding: 10.5px 20px;

      .inner-content {
        span {
          color: #7680c5;
        }
      }
    }

    &.fifth-width {
      width: 50%;
    }

    &.small-font {
      .inner-content {
        span {
          font-size: 12px;
        }
      }
    }

    &.max-height-button {
      max-height: 40px;
    }

    &.modal-grey-button {
      border-radius: 6px;
      border: 2px solid #4d5696;
      background: #3d457c;

      .inner-content {
        span {
          color: $fontColor;
          font-size: 13px;
        }
      }
    }

    &.login-button {
      border-radius: 6px;
      background: #3d457c;
      min-width: auto;
      padding-left: 20px;
      padding-right: 20px;
      height: 42px;
      border-radius: 6px;
      border: 1px solid #363c5e;
      background: linear-gradient(
        180deg,
        rgba(124, 131, 177, 0.1) 0%,
        rgba(94, 103, 158, 0.1) 100%
      );
      transition: all 0.3s ease;

      .inner-content {
        span {
          color: $fontColor;
          font-size: 13px;
        }
      }

      @media screen and (max-width: 1100px) {
        height: 40px;
      }

      &:hover {
        border-radius: 6px;
        background:
          linear-gradient(#222846, #222846) padding-box,
          linear-gradient(to right, #6b7198, #656c96) border-box;
        border-image: linear-gradient(180deg, #6e759d, #515a89) 1;
        box-shadow: inset 0px 0px 2px 0px #7d83a8;
        /* Inner shadow effect */
        border: 1.2px solid #7d83a8;
        /* Required for border-image */
        transition: all 0.3s ease;
      }
    }
  }

  &.input-copy-button {
    background: none;

    .inner-content .icon-container {
      width: 20px;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  &.square-icon-grey {
    display: flex;
    min-width: 0;
    border-radius: 6px;
    border: 2px solid #282f58;
    background: #262c52;
    padding: 11px;

    .inner-content {
      .icon-container {
        width: 22px;
        height: 22px;
        margin: 0;
        display: flex;
        align-items: center;
      }
    }

    &.icon-left {
      padding-left: 5px;
    }
  }

  &.fixed-height-mid {
    height: 40px;
  }

  &.buy-crypto-tab {
    background-color: $btn-fill-color;
    height: 109px;
    padding-left: 25px;
    border: 1px solid #5e679e44;
    background: radial-gradient(ellipse at 50% 150%, #5e679e40, #5e679e04);

    &:hover {
      background-color: #23294d42;
    }
  }

  &.buy-crypto-mini {
    background-color: $btn-fill-color;
    height: 60px;
    padding-left: 25px;

    border: 1px solid #5e679e44;
    padding: 20px;
    background: radial-gradient(ellipse at 50% 150%, #5e679e40, #5e679e04);

    .gradient-item {
      opacity: 0;
      transition: opacity 0.4s ease-in-out;

      background: radial-gradient(ellipse at 50% 150%, #0077db, #0078db16);
    }

    &:hover {
      background-color: #23294d42;
    }

    &__selected {
      border: 2px solid #0077db;

      .gradient-item {
        opacity: 0.5;
      }
    }
  }

  &.see-all-button {
    width: 100%;

    border-radius: 6px;

    .inner-content {
      gap: 10px;
    }
  }

  &.gift-card-modals-btn {
    &:hover {
      div {
        color: #949bc2;
      }

      svg {
        fill: #949bc2;

        path {
          fill: #949bc2;
        }
      }
    }
  }

  &.no-style {
    padding: 0;
    display: block;
    border: none;
    text-align: initial;
    background: #191f3b;
  }

  &.add-case-battle-btn {
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #1e63a2;
    transition: all 0.1 ease-in-out;

    &:hover {
      border-radius: 6px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background:
        linear-gradient(0deg, #487eb7 0%, #487eb7 100%),
        rgba(255, 255, 255, 0.2);
    }
  }

  &.battles-return {
    width: 30px;
    height: 30px;
    background-color: #262c52;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    padding-right: 1px;

    .inner-container .icon-container {
      margin-right: 0px;
    }

    &:hover {
      .icon-container {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  &.wallet-setting {
    border-radius: $radius;

    background-color: #ffffff10;

    &:hover {
      background-color: #31375b;
    }
  }

  &.wallet-crypto-dropdown {
    width: 100%;
    height: 54px;
    border-width: 1px;

    .inner-content {
      width: 100%;
      justify-content: space-between;
    }
  }

  &.wallet-crypto-dropdown-buy {
    width: 100%;
    height: 54px;
    border-width: 1px;
    background-color: #212749;

    .inner-content {
      width: 100%;
      justify-content: space-between;
    }

    &.active-button {
      background-color: #2b3155;
    }
  }
  &.tip-user {
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.2) 0%,
      rgba(94, 103, 158, 0.2) 100%
    );
  }
}

.challenges-button {
  height: 38px !important;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(124, 131, 177, 0.15) 0%,
    rgba(94, 103, 158, 0.12) 100%
  );

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    padding: 1px;
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  &.green {
    border: 1px solid #7dd934;

    &::before {
      display: none;
    }
  }
}

.case-select {
  width: 25%;
}

.no-border {
  border: none !important;
}

.icon-container-sports {
  .icon-container {
    width: 32px !important;
    height: 32px !important;
  }
  svg {
    width: 24px !important;
    height: 24px !important;
  }
}
